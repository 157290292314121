import PopupVideo from "./popupVideo";

const handleButtonClick = async (e, section) => {
    const { target } = e;
    target.style.pointerEvents = 'none';
    const youtubeLink = target.getAttribute('data-url');

    const loader = section.querySelector(".cta__loader");
    loader.classList.remove("hide");
    if (youtubeLink && youtubeLink.length > 0) {
        try {
            const formData = new FormData();
            formData.append('action', 'media_popup');
            formData.append('type', 'wideo');
            formData.append('id', youtubeLink);
            const req = await fetch(ajax.url, {
                method: 'POST',
                body: formData,
            });

            const res = await req.json();
            target.style.pointerEvents = 'all';

            if (!res) {
                console.log('error');
                return false;
            }

            const { html } = res;
            document.body.insertAdjacentHTML('beforeend', html);
            const popup = document.querySelector('[data-popup]');
            if (popup) new PopupVideo(popup);
        } catch (error) {
            console.log(`error: ${error}`);
            target.style.pointerEvents = 'all';
        } finally {
            loader.classList.add("hide");
        }
    }
};

const initCtaSection = (ctaSection) => {
    if (!ctaSection) return;
    const videoButton = ctaSection.querySelectorAll('.cta__video-btn');
    if (videoButton) videoButton.forEach(item => {
        item.addEventListener('click', (e) => handleButtonClick(e, ctaSection))
    });
}

export default initCtaSection;